.App {
  text-align: center;
  height: 100%;
}
.App .navbar {
  height: 79.6px;
}
.App header {
  height: 100%;
}
.App header .content {
  height: 100%;
}
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.App-note {
  color: #61dafb;
  background-color: blanchedalmond;
}

.pop-upbox {
  background-color: #095cc4;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Scanner-box {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: #3795bd;
}

h4 {
  color: #fff;
  text-align: center;
  margin: 0px auto 16px;
}

.borderbtnhome {
  position: relative;
  border: 2px solid #2ea3f2;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.8em !important;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  color: #2ea3f2 !important;
}

.none {
  display: none;
}

svg {
  padding: 21px;
  background: #fff;
}

.data-container {
  margin: 25px 10px 0px 10px;
  padding: 25px;
  border: 1px solid white;
  color: white;
  width: 80%;
}

.detail {
  display: flex;
}

p.info {
  padding-left: 10px;
}

/**saurabh css**/
.navbar {
  padding-bottom: 15px;
  padding-top: 15px;
  background: #065ec9;
}

.profileAccess {
  display: flex;
}

.profileIcon {
  cursor: pointer;

  margin-left: 30px;
  width: 36px;
}

.dropDown {
  position: relative;
}

.dropdown-profile {
  list-style: none;
  color: white;
  background-color: #007bffc4;
  position: absolute;
  padding-left: 0px;
  width: 230px;
  top: 58px;
  left: -53px;
  border: 1px solid #0175f3;
}

.dropdown-list1 {
  padding: 10px;
  border-bottom: 1px solid #ffff;
}

li:hover {
  background-color: #065ec9;
  cursor: pointer;
}

.dropdown-list {
  padding: 5px;
}

a.nav-link {
  color: white !important;
}

.login_btn {
  background: white;
}

.cartIcon {
  margin-left: 30px;
  width: 36px;
}

.cartText {
  font-style: italic;
  font-weight: 600;
  font-family: sans-serif;
  font-size: 16px;
  color: white;
}

header .hero-section {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: calc(100vh - 75px) !important;
  height: 100vh;
  /* background-image: linear-gradient(180deg,#ffffff 4%,rgba(255,255,255,0) 100%),url(back1.png)!important; */
  background-image: url(ecomm.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-repeat: cover;
}

.modal-content {
  margin-top: 70px;
  background: #065ec9;
}

.modal-header {
  border-bottom: none;
  padding-bottom: 0px;
}

.p-btn {
  background: white;
  color: #065ec9;
  width: 110px;
}

.p-btn:hover {
  background: white;
  color: #065ec9;
}

.modal-footer {
  border-top: none;
  display: flex;
  justify-content: center;
}

img.spinner {
  width: 135px !important;
}

.welcomeLabel {
  color: white;
  margin-right: 10px;
  margin-bottom: 0px;
}

.btnView {
  margin-right: 10px;
}

.logo {
  filter: invert(100%);
  max-width: 50px;
}

.logo-text {
  font-style: italic;
  font-family: sans-serif;
  font-size: 30px;
  color: white;
}

.clickable {
  cursor: pointer;
}
