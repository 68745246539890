.custom-modal {
  top: 7%;
  max-width: 90% !important;
}

.custom-modal .modal-content {
  background-color: white;
}
.custom-modal .btn-success {
  background-color: #3085d6;
}
.custom-modal .btn-danger {
  background-color: #d33;
}
header .hero-image {
  height: 1200px !important;
  background-image: url(./../../ecomm.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
/*   confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33", */
